import abi from "./abi.json";

const contractAddress = "0x7DEe1c622eF67Ec37B0a67BD67d4dAAb767A6741";
const chainId = 1;

export {
  abi,
  contractAddress,
  chainId,
}
